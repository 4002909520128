<template>
  <div>
    <v-btn bottom
           color="#d52787"
           dark
           fab
           fixed
           right
           @click="dialog = !dialog">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog">
      <v-card light class="pb-10">
        <v-card-title class=" darken-2">
          Nuevo Viaje
        </v-card-title>
        <v-container>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1"
                              color="#d52787"
                              step="1">
                Datos del viaje
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 2"
                              color="#d52787"
                              step="2">
                Título y descripción
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 3" color="#d52787" step="3">
                Asientos reservados
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step color="#d52787" step="4">
                Resumen
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row class="mb-5">
                  <v-col cols="12" md="12">
                    <v-alert
                        outlined
                        type="warning"
                        prominent
                        v-if="errorPreset"
                        border="left">
                      Ha surgido un error al crear el viaje, inténtalo de nuevo más tarde
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field v-model="travel.ref" color="#d52787" label="Referencia"/>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select v-model="bus" :items="busItems" color="#d52787" label="Típo de Bus"></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select v-model="typus" :items="typeItems" color="#d52787" label="Típo de viaje"></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field type="date" v-model="travel.date" color="#d52787" label="Fecha inicio"/>
                  </v-col>
                  <v-col cols="12" md="2" v-if="typus===2 || typus === 3">
                    <v-text-field type="number" v-model="travel.duration" color="#d52787" label="Días de duración"/>
                  </v-col>
                  <v-col cols="12" md="2" v-else>
                    <v-text-field type="number" v-model="travel.duration"  color="#d52787" label="Días de duración" disabled/>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field type="number" v-model="travel.price" color="#d52787" label="Precio base"/>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-alert
                        outlined
                        type="warning"
                        prominent
                        v-if="errorImage"
                        border="left">
                      Ha surgido un error al subir la imagen, inténtalo de nuevo
                    </v-alert>
                    <label class="mr-5">Seleccionar imágen del viaje</label>
                    <input type="file" ref="image" multiple="multiple">
                  </v-col>
                  <v-col cols="12" md="4" v-if="typus===2 || typus===3">
                    <v-checkbox v-model="singleRoom" color="#d52787"
                                label="Habitaciones Individuales"/>
                    <v-text-field v-if="singleRoom" prepend-icon="mdi-currency-eur" type="number"
                                  v-model="singlePlusRoom" color="#d52787" label="Suplemento Hab. Individual"/>
                  </v-col>
                  <v-col cols="12" md="4" v-if="typus===2 || typus===3">
                    <v-checkbox v-model="doubleRoom" color="#d52787" label="Habitaciones Dobles"/>
                    <v-text-field v-if="doubleRoom" prepend-icon="mdi-currency-eur" type="number"
                                  v-model="doublePlusRoom" color="#d52787" label="Suplemento Hab. Doble"/>
                  </v-col>
                  <v-col cols="12" md="4" v-if="typus===2 || typus===3">
                    <v-checkbox v-model="tripleRoom" color="#d52787" label="Habitaciones Triples"/>
                    <v-text-field v-if="tripleRoom" prepend-icon="mdi-currency-eur" type="number"
                                  v-model="triplePlusRoom" color="#d52787" label="Suplemento Hab. Triple"/>
                  </v-col>
                </v-row>
                <v-btn
                    color="#d52787"
                    dark
                    :loading="loading1"
                    @click="presetTravel">
                  Continuar
                </v-btn>
                <v-btn class="ml-2" outlined text @click="previousStem(1)">
                  Cerrar
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-row>
                  <v-col cols="12" md="10">
                    <h4>Título
                      <country-flag v-if="lang===1" country='es' size='small'/>
                      <country-flag v-else country='es-CA' size='small'/>
                    </h4>
                    <v-text-field v-if="lang===1" v-model="travel.titleES" color="#d52787"/>
                    <v-text-field v-else v-model="travel.titleCAT" color="#d52787"/>
                  </v-col>
                  <v-col cols="12" md="12">
                    <label class="mr-5">Selecciona políticas de viaje</label>
                    <input type="file" ref="file" multiple="multiple">
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select :items="langItems" v-model="lang" label="Idioma">
                      <template slot="selection" slot-scope="data">
                        <country-flag v-if="lang===1" :country='data.item.ISO' size='normal'/>
                        {{ data.item.text }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <country-flag :country='data.item.ISO' size='normal'/>
                        {{ data.item.text }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <h4>Descripción
                      <country-flag v-if="lang===1" country='es' size='small'/>
                      <country-flag v-else country='es-CA' size='small'/>
                    </h4>
                    <vue-editor v-if="lang===1" v-model="travel.descriptionES"/>
                    <vue-editor v-else v-model="travel.descriptionCAT"/>
                  </v-col>
                </v-row>
                <v-btn
                    class="mt-5"
                    color="#d52787"
                    dark
                    @click="nextStep(2)">
                  Continuar
                </v-btn>
                <v-btn class="ml-2 mt-5" outlined text @click="previousStem(2)">
                  Atrás
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-chip-group
                    :multiple="true"
                    active-class="deep-purple accent-4 white--text"
                    column
                    style="display: block;">
                  <div v-if="bus===1" style="display: block; margin: 0 auto;">
                    <div class="line1" v-for="busLine in bus55" v-bind:key="busLine.line">
                      <div class="seats2" :style="'margin-left: '+calculateMargin(busLine.line)">
                        <v-tooltip top v-for="busseat in busLine.seats" v-bind:key="busseat">
                          <template #activator="{ on }">
                            <div v-on="on" class="d-inline-block">
                              <v-chip
                                  active-class="selectedSeat" @click="selectSeat(busseat)"
                                  v-bind:disabled="busseat==='puerta' || busseat ==='conductor' || busseat==='reservado' || busseat ==='ocupado'"
                              >
                                <img :src="getBusSeatImg(busseat)" height="20" :alt="busseat"/>
                              </v-chip>
                            </div>
                          </template>
                          <span>{{ busseat }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                  <div v-if="bus===2" style="display: block; margin: 0 auto;">
                    <div class="line1" v-for="busLine in bus63" v-bind:key="busLine.line">
                      <div class="seats2" :style="'margin-left: '+calculateMargin(busLine.line)">
                        <v-tooltip top v-for="busseat in busLine.seats" v-bind:key="busseat">
                          <template #activator="{ on }">
                            <div v-on="on" class="d-inline-block">
                              <v-chip
                                  active-class="selectedSeat"  @click="selectSeat(busseat)"
                                  v-bind:disabled="busseat==='puerta' || busseat ==='conductor' || busseat==='reservado' || busseat ==='ocupado'"
                              >
                                <img :src="getBusSeatImg(busseat)" height="20" :alt="busseat"/>
                              </v-chip>
                            </div>
                          </template>
                          <span>{{ busseat }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                  <div v-if="bus===3" style="display: block; margin: 0 auto;">
                    <div class="line1" v-for="busLine in bus71" v-bind:key="busLine.line">
                      <div class="seats2" :style="'margin-left: '+calculateMargin(busLine.line)">
                        <v-tooltip top v-for="busseat in busLine.seats" v-bind:key="busseat">
                          <template #activator="{ on }">
                            <div v-on="on" class="d-inline-block">
                              <v-chip
                                  active-class="selectedSeat"  @click="selectSeat(busseat)"
                                  v-bind:disabled="busseat==='puerta' || busseat ==='conductor' || busseat==='reservado' || busseat ==='ocupado'"
                              >
                                <img :src="getBusSeatImg(busseat)" height="20" :alt="busseat"/>
                              </v-chip>
                            </div>
                          </template>
                          <span>{{ busseat }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </v-chip-group>
                <v-btn
                    color="#d52787"
                    dark
                    class="mt-5"
                    @click="nextStep(3)">
                  Continuar
                </v-btn>
                <v-btn class="ml-2 mt-5" outlined text @click="previousStem(3)">
                  Atrás
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field disabled v-model="travel.titleES" label="Título"/>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field disabled v-model="travel.ref" label="Referencia"/>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field v-if="bus===1" disabled value="55 Plazas" label="Bus"/>
                    <v-text-field v-if="bus===2" disabled value="63 Plazas" label="Bus"/>
                    <v-text-field v-if="bus===3" disabled value="71 Plazas" label="Bus"/>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field v-if="typus===1" disabled value="Bus" label="Tipo de viaje"/>
                    <v-text-field v-if="typus===2" disabled value="Bus + Hotel" label="Tipo de viaje"/>
                    <v-text-field v-if="typus===3" disabled value="Club" label="Tipo de viaje"/>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field disabled type="date" v-model="travel.date" label="Fecha inicio"/>
                    <v-text-field disabled v-model="travel.duration" label="Días de duración"/>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field disabled v-model="travel.price" label="Precio base"/>
                    <v-text-field disabled v-model="seats" label="Asientos Seleccionados"/>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox disabled  v-model="singleRoom" label="Habitaciones individuales"/>
                    <v-checkbox disabled  v-model="doubleRoom" label="Habitaciones dobles"/>
                    <v-checkbox disabled  v-model="tripleRoom" label="Habitaciones triples"/>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field  disabled v-model="singlePlusRoom" label="Suplemento"/>
                    <v-text-field disabled v-model="doublePlusRoom" label="Suplemento"/>
                    <v-text-field  disabled v-model="triplePlusRoom" label="Suplemento"/>
                  </v-col>

                </v-row>
                <v-btn
                    class="mt-5"
                    color="#d52787"
                    dark
                    :loading="loading"
                    @click="setTravel">
                  Crear
                </v-btn>
                <v-btn class="ml-2 mt-5" outlined text @click="previousStem(4)">
                  Atrás
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";

export default {
  name: "NewTravelForm",
  props:['refresh'],
  components: {
    VueEditor
  },
  data: () => ({
    e1: 1,
    errorImage:false,
    errorPreset: false,
    tid:null,
    load1:false,
    loading:false,
    loading1:false,
    dialog: false,
    travel: [],
    singleRoom:false,
    doubleRoom:false,
    tripleRoom:false,
    singlePlusRoom:0,
    doublePlusRoom:0,
    triplePlusRoom:0,
    bus: 1,
    typus: 1,
    lang: 1,
    seats:[],
    langItems: [{"text": "Castellano", "value": 1, "ISO": "es"}, {"text": "Català", "value": 2, "ISO": "es-CA"}],
    busItems: [{text: '55 Plazas', value: 1}, {text: '63 Plazas', value: 2}, {text: '71 Plazas', value: 3}],
    typeItems: [{text: 'Bus', value: 1}, {text: 'Bus + Hotel', value: 2}, {text: 'Bus club', value: 4},{text: 'Bus + hotel club', value: 3}],
    bus55: [
      {line: 1, seats: ["puerta", 4, 8, 12, 16, 20, 24, 28, "puerta", 34, 38, 42, 46, 50, 55]},
      {line: 2, seats: ["reservado", 3, 7, 11, 15, 19,23, 27, "puerta", 33, 37, 41, 45, 49, 54]},
      {line: 3, seats: [53]},
      {line: 4, seats: [2, 6, 10, 14, 18, 22, 26, 30, 32, 36, 40, 44, 48, 52]},
      {line: 5, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 31, 35, 39, 43, 47, 51]},
    ],
    bus63: [
      {line: 6, seats: ["puerta", 3, 7, 11, 15, 19, 23, 27, "puerta", 31, 35, 39, 43, 47, 51, 55, 59]},
      {line: 7, seats: ["reservado", 4, 8, 12, 16, 20, 24, 28, "puerta", 32, 36, 40, 44, 48, 52, 56, 60]},
      {line: 8, seats: [63]},
      {line: 9, seats: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 42, 46, 50, 54, 58, 62]},
      {line: 10, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41, 45, 49, 53, 57, 61]},
    ],
    bus71: [
      {line: 11, seats: ["puerta", 4, 8, 12, 16, 20, 24, 28, 32, "puerta", 36, 42, 46, 50, 54, 58, 62, 66, 71]},
      {line: 12, seats: ["reservado", 3, 7, 11, 15, 19, 23, 27, 31, "puerta", 35, 41, 45, 49, 53, 57, 61, 65, 70]},
      {line: 13, seats: [69]},
      {line: 14, seats: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 40, 44, 48, 52, 56, 60, 64, 68]},
      {line: 15, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 39, 43, 47, 51, 55, 59, 63, 67]},
    ],
  }),
  watch:{
    typus(t){
      if(t === 1 || t===4)
        this.travel.duration = 1;
    }
  },
  mounted() {
    this.travel.duration = 1;
  },
  methods: {
    previousStem(n) {
      if (n === 1)
        this.dialog = false;
      else this.e1 = n - 1
    },
    nextStep(n) {
      if (n === this.steps) {
        this.dialog = false
      } else {
        this.e1 = n + 1
      }
    },
    closeDialog() {
      this.dialog = false;
      this.refresh()
    },
    getBusSeatImg(busseat) {
      const base = "/assets/css/images/bus/";
      switch (busseat) {
        case 'puerta':
          return base + 'door.png';
        case 'conductor':
          return base + 'driver.png';
        case 'ocupado':
          return base + 'busyseat.png';
        default:
          return base + 'seat.png';
      }
    },
    presetTravel(){
      this.load1 = true;
      this.loading1 = true;
      if(this.bus === 1) this.travel.places = 55;
      else if(this.bus === 2) this.travel.places = 63;
      else if(this.bus === 3) this.travel.places = 71;
      let url = this.base_url + "/admin/crearviaje"
      let formData = new FormData();
      formData.set('ref',this.travel.ref);
      formData.set('typus', this.typus);
      formData.set('sRoom',this.singleRoom);
      formData.set('dRoom',this.doubleRoom);
      formData.set('tRoom',this.tripleRoom);
      formData.set('sPlus',this.singlePlusRoom);
      formData.set('dPlus',this.doublePlusRoom);
      formData.set('tPlus',this.triplePlusRoom);
      formData.set('places',this.travel.places);
      formData.set('bus',this.bus);
      formData.set('price',this.travel.price);
      formData.set('date',this.travel.date);
      formData.set('duracion',this.travel.duration);
      if(this.$refs.image.files.length>0){
        for (let i = 0; i < this.$refs.image.files.length; i++) {
          let file = this.$refs.image.files[i];
          formData.append('images[' + i + ']', file);
        }
      }
      this.axios.post(url, formData).then(response => {
        this.errorPreset = false;

        this.load1 = false;
        this.loading1 = false;
        this.errorImage = false;
        if(response.data.travelID === '0'){
          this.errorPreset = true;
          this.$ma.trackEvent({
            category: 'new travel',
            action: 'Error in preset travel',
            properties:{version:this.version}
          });
        }
        else{
          this.tid = response.data.travelID
          this.nextStep(1)
          this.$ma.trackEvent({
            category: 'new travel',
            action: 'Preset travel',
            properties:{version:this.version, travelID: response.data.travelID}
          });
        }
      }).catch(e=>{
        if(e.response.data.message == 'failed to upload image')
          this.errorImage = true
        this.loading1 = false;
      });
    },
    selectSeat(sid){
      let url = this.base_url + "/admin/reservarAsiento"
      let formData = new FormData();
      formData.set('tid',this.tid)
      formData.set('seatid', sid);
      this.axios.post(url, formData).then(response => {
        this.seats=[];
        response.data.seats.forEach(seat=>{
          this.seats.push(seat.seatID)
        })
        this.$ma.trackEvent({
          category: 'new travel',
          action: 'Select seats',
          properties:{version:this.version, seats: this.seats}
        });
      });
    },
    setTravel() {
      this.loading = true;
      if(this.bus === 1) this.travel.places = 55;
      else if(this.bus === 2) this.travel.places = 63;
      else if(this.bus === 3) this.travel.places = 71;
      let url = this.base_url + "/admin/publicarviaje"
      let formData = new FormData();
      formData.set('tid',this.tid)
      formData.set('titleES', this.travel.titleES);
      formData.set('titleCAT', this.travel.titleCAT);
      formData.set('descES',this.travel.descriptionES);
      formData.set('descCAT',this.travel.descriptionCAT);
      if(this.$refs.file.files.length>0){
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          let file = this.$refs.file.files[i];
          formData.append('files[' + i + ']', file);
        }
      }
      this.axios.post(url, formData).then(() => {
        this.loading = false;
        this.dialog = false;
        this.refresh();
        this.$ma.trackEvent({
          category: 'new travel',
          action: 'Publish travel',
          properties:{version:this.version}
        });
      });
    },
    calculateMargin(line) {
      switch (line) {
        case 1:
          return "3.75em";
        case 2:
          return "3.75em";
        case 3:
          return "49.25em";
        case 4:
          return "7em";
        case 5:
          return "3.75em";
        case 6:
          return "3.75em";
        case 7:
          return "3.75em";
        case 8:
          return "56em";
        case 9:
          return "7.25em";
        case 10:
          return "4em";
        case 11:
          return "2.75em";
        case 12:
          return "2.75em";
        case 13:
          return "61.25em";
        case 14:
          return "6.25em";
        case 15:
          return "3em";
      }
    },
  }
}
</script>

<style scoped>
.v-slide-group__content {
  display: block !important;
}

.selectedSeat {
  background: #b52787;
}
</style>