<template style="background: white">
  <v-card light>
    <v-card-title>
      <h3 style="color: #d52787">Viajes Activos</h3>
      <v-icon class="ml-2" color="#d52787 !important" small v-on:click="getData">mdi-reload</v-icon>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          color="#d52787"
          hide-details
          label="Buscar"
          single-line/>
    </v-card-title>
    <v-alert
        v-if="errorInCharge"
        dense
        outlined
        type="error">
      Ha habido un error al obtener los datos, inténtalo de nuevo más tarde
    </v-alert>
    <v-data-table
        :footer-props="{
          'items-per-page-text':'Viajes por página'
        }"
        :headers="headers"
        :items="travels"
        :loading="!loaded"
        :search="search"
        loading-text="Cargando... Por favor, espera"
        no-data-text="Todavía no hay viajes activos">
      <template v-slot:item.typus="{item}">
        <span v-if="item.typus==='1'">Bus</span>
        <span v-if="item.typus==='2'">Bus + Hotel</span>
        <span v-if="item.typus==='4'">Bus <b>Club</b></span>
        <span v-if="item.typus==='3'">Bus + Hotel <b>Club</b></span>
      </template>
      <template v-slot:item.busType="{item}">
        <span class="text-center" v-if="item.busType==='1'">55 plazas</span>
        <span class="text-center" v-if="item.busType==='2'">63 plazas</span>
        <span class="text-center" v-if="item.busType==='3'">71 plazas</span>
      </template>
      <template v-slot:item.showHome="{item}">
        <span class="text-center">
          <v-icon  v-if="item.showHome==='1'" color="green" @click="homeAd(item.travelID)">mdi-check</v-icon>
        <v-icon v-else color="red" @click="homeAd(item.travelID)">mdi-close</v-icon>
        </span>
      </template>
      <template v-slot:item.price="{item}">
        {{ item.price }} €
      </template>
      <template v-slot:item.reservations="{item}">
        <v-progress-linear
            :value="Math.ceil((item.seats * 100) / item.places)"
            color="pink"
            height="20">
          <strong>{{ Math.ceil((item.seats * 100) / item.places) }}%</strong>
        </v-progress-linear>
      </template>
      <template v-slot:item.actions="{item}">
        <router-link :to="'/viaje/'+item.travelID">
          <TooltipIcon icon="see" text="Ver viaje"/>
        </router-link>
        <TooltipIcon :id="item.travelID" type="travel" :reference="item.ref"  icon="copy" text="Copiar enlace" :funct="showSnack" />
        <TooltipIcon :id="item.travelID" :reference="item.ref" icon="delete" text="Borrar viaje" :funct="getData" />
        <TooltipIcon :id="item.travelID" :reference="item.ref" icon="clock" text="Finalizar viaje" :funct="getData" />

      </template>
    </v-data-table>
    <NewTravelForm :refresh="getData"/>
  </v-card>
</template>

<script>
// @ is an alias to /src

import TooltipIcon from "../components/general/TooltipIcon";
import NewTravelForm from "../components/travels/NewTravelForm";

export default {
  name: 'Home',
  components: {NewTravelForm, TooltipIcon},
  data() {
    return {
      search: '',
      timeout: 2000,
      errorInCharge: false,
      loaded: false,
      snackbar: false,
      message: 'probando',
      travels: [],
      headers: [
        {
          text: 'Ref',
          align: 'start',
          value: 'ref',
        },
        {text: 'Viaje', value: 'titleES'},
        {text: 'Tipo', value: 'typus'},
        {text: 'Ocupación', value: 'reservations'},
        {text: 'Autobus', value: 'busType'},
        {text: 'Precio base', value: 'price'},
        {text: 'Fecha', value: 'date'},
        {text: 'Destacado', value: 'showHome'},
        {text: 'Acciones', value: 'actions'}
      ]
    }
  },
  mounted() {
    this.getData();
    this.refreshUnconfirmedReservations();
  },
  methods: {
    refreshUnconfirmedReservations() {
      let url = this.base_url + "/admin/borrarpendientes";
      this.axios.get(url).then(() => {
      })
    },
    getData() {
      let url = this.base_url + "/admin/viajesactivos"
      this.axios.get(url).then(response => {
        this.travels = response.data.travels;
        this.loaded = true;
        this.$ma.trackEvent({
          category: 'Home',
          action: 'get travels',
          properties:{version:this.version}
        });
      });
    },
    homeAd(tid){
      let url = this.base_url + "/admin/destacarAnuncio"
      let formData = new FormData();
      formData.set('tid',tid)
      this.axios.post(url, formData).then(() => {this.getData();
        this.$ma.trackEvent({
          category: 'Home',
          action: 'destacar anuncio',
          properties:{version:this.version, travelID: tid}
        });});
    },
    showSnack() {
      this.snackbar = true
    }
  }
}
</script>
