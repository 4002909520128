<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-snackbar
          v-model="snackbar"
          :timeout="timeout">
        Enlace copiado
        <template v-slot:action="{ attrs }">
          <v-btn
              color="#d52787"
              text
              v-bind="attrs"
              @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
      <v-icon
          v-if="icon === 'copy' && type=='travel'"
          class="mr-4 listIcon" small
          v-bind="attrs"
          v-on="on"
          v-clipboard:copy="message" v-clipboard:success="onCopy">
        mdi-file-document-multiple-outline
      </v-icon>
      <v-icon
          v-if="icon === 'copy' && type=='payment'"
          class="mr-4 listIcon" small
          v-bind="attrs"
          v-on="on"
          v-clipboard:copy="url" v-clipboard:success="onCopy">
        mdi-file-document-multiple-outline
      </v-icon>
      <v-icon
          v-if="icon === 'see'"
          class="mr-4 listIcon" small
          v-bind="attrs"
          v-on="on">
        mdi-eye
      </v-icon>
      <v-icon
          v-if="icon === 'delete'"
          class="mr-4 listIcon" small
          v-bind="attrs"
          v-on="on"
          @click="removeTravel">
        mdi-close
      </v-icon>
      <v-icon
          v-if="icon === 'clock'"
          class="mr-4 listIcon" small
          v-bind="attrs"
          v-on="on"
          @click="finishTravel">
        mdi-alarm-check
      </v-icon>
      <v-icon
          v-if="icon === 'delres'"
          class="mr-4 listIcon" small
          v-bind="attrs"
          v-on="on"
          @click="funct(id)">
        mdi-close
      </v-icon>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TooltipIcon",
  props: ['icon', 'text', 'type', 'url', 'funct', 'id', 'reference'],
  data() {
    return {
      message: '',
      timeout: 2000,
      snackbar: false
    }
  },
  created() {
    this.message = this.travel_url + this.reference
  },
  methods: {
    onCopy: function () {
      this.snackbar = true
    },
    removeTravel: function () {
      let url = this.base_url + "/admin/borrarviaje/" + this.id
      this.axios.get(url).then(() => {
        this.funct()
      });
    },
    finishTravel: function () {
      let url = this.base_url + "/admin/finalizarviaje/" + this.id
      this.axios.get(url).then(() => {
        this.funct()
      });
    }
  }
}
</script>

<style scoped>
.listIcon {
  color: #d52787 !important
}

.listIcon:hover {
  color: #681345FF !important;
  cursor: pointer;
}
</style>